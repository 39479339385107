const localAppMetadata = {
    "uid": "blt169555ec6824b6ac",
    "_version": 84,
    "locale": "en-us",
    "ACL": {},
    "_in_progress": false,
    "created_at": "2023-03-06T15:29:09.646Z",
    "created_by": "blt84c7dbee95981303",
    "field_specific_prompt": {
        "json_rte": ""
    },
    "generate_from_other_fields": [
        {
            "title": "Outline",
            "_metadata": {
                "uid": "cs8287640d5de2511a"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt7641c90269ac3aa4",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:51:36.328Z",
                "updated_at": "2023-06-30T11:51:36.328Z",
                "content_type": "image/svg+xml",
                "file_size": "1330",
                "filename": "ListBullets.svg",
                "title": "ListBullets.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.257Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt7641c90269ac3aa4/649ec1c81ea82969c4f58663/ListBullets.svg"
            },
            "prompt": "Generate an outline from this text: {{field_data}}",
            "testid": "generate-outline"
        },
        {
            "title": "Headline",
            "_metadata": {
                "uid": "cse0c23377e2dd4f39"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt40d5b43a35413240",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:52:47.700Z",
                "updated_at": "2023-06-30T11:52:47.700Z",
                "content_type": "image/svg+xml",
                "file_size": "1157",
                "filename": "TextHOne.svg",
                "title": "TextHOne.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.282Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt40d5b43a35413240/649ec20fa60a7946664128cf/TextHOne.svg"
            },
            "prompt": "Write an SEO-enhanced headline without double quotes for this text: {{field_data}}",
            "testid": "generate-headline"
        },
        {
            "title": "Summary",
            "_metadata": {
                "uid": "cs1593b8f642f8c5fc"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt0f409b9c4c6aa2fa",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:52:23.826Z",
                "updated_at": "2023-06-30T11:52:23.826Z",
                "content_type": "image/svg+xml",
                "file_size": "1582",
                "filename": "Multiline_Textbox.svg",
                "title": "Multiline_Textbox.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.309Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt0f409b9c4c6aa2fa/649ec1f7e9365ae3cfc364bb/Multiline_Textbox.svg"
            },
            "prompt": "Write a concise and comprehensive summary from this text, as if it were a shorter version of the article with the same tone and viewpoint. Make it three to five sentences in length and ensure to capture the main points and key details:\n{{field_data}}",
            "testid": "generate-summary"
        },
        {
            "title": "SEO Tags",
            "_metadata": {
                "uid": "cs3cd0457d2bf34855"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "bltb4de3fbb3484a66a",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:58:30.942Z",
                "updated_at": "2023-06-30T11:58:30.942Z",
                "content_type": "image/svg+xml",
                "file_size": "1838",
                "filename": "Tag.svg",
                "title": "Tag.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-07-10T09:19:43.020Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltb4de3fbb3484a66a/649ec366b70242a2a1caca04/Tag.svg"
            },
            "prompt": "SEO keywords are words or phrases related to web content take make it possible for search engines to index content in a way that people will find it. SEO keywords do not need to be specifically mentioned in the text, but should be relevant and should help people find this text in search engines.\n\nSEO tags should be separated by commas, without a period at the end and should be all lower case, like this: \n\ntechnology, composable industry, search engine performance, SEO\n\nAct as a professional personalization expert and generate several SEO tags in comma-separated format from this text: {{field_data}}",
            "testid": "generate-seo-tags"
        },
        {
            "title": "User Persona Tags",
            "_metadata": {
                "uid": "cs7ff61ea0f1758a48"
            },
            "tooltip": "",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "bltb1b238221691fdb3",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-07-10T09:10:07.125Z",
                "updated_at": "2023-07-10T09:10:07.125Z",
                "content_type": "image/svg+xml",
                "file_size": "1790",
                "filename": "User_Persona_Tags.svg",
                "title": "User_Persona_Tags.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-07-10T09:19:43.095Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltb1b238221691fdb3/64abcaef9fa42358bd9f49a4/User_Persona_Tags.svg"
            },
            "prompt": "A persona represents a segment of audience members who exhibit similar behaviors or have similar interests. Persona tags can be used to identify audience members who would be interested in this blog post for the purpose of personalization and audience targeting. \n\nKey persona tags should be separated by commas, without a period at the end and should be all lower case, like this: \n\nmarketing professionals, developers, business users, pet owners\n\nAct as a professional personalization expert and generate several key persona tags from this text: \"{{field_data}}\"",
            "testid": ""
        },
        {
            "title": "Blog Post",
            "_metadata": {
                "uid": "cs4165d3fa62c3b35a"
            },
            "tooltip": "",
            "icon": {
                "uid": "bltbecad06d8ed95af7",
                "_version": 1,
                "parent_uid": "blt428f2156d364d6ed",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:52:23.835Z",
                "updated_at": "2023-06-30T11:52:23.835Z",
                "content_type": "image/svg+xml",
                "file_size": "1663",
                "filename": "Rich_Text_Editor.svg",
                "title": "Rich_Text_Editor.svg",
                "ACL": {},
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "time": "2023-09-15T14:52:51.355Z",
                    "user": "bltcad6c6f379d5fb4d",
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltbecad06d8ed95af7/649ec1f7b93cad77f3a70e22/Rich_Text_Editor.svg"
            },
            "prompt": "Generate an SEO-enhanced blog post from this text: \n{{field_data}}",
            "testid": "generate-blog-post"
        }
    ],
    "jsonrte_toolbar_options": [
        {
            "title": "Optimize text",
            "_metadata": {
                "uid": "cs03447d78e7ac39a5"
            },
            "tooltip": "Enhance SEO, fix grammar and spelling",
            "icon": {
                "_version": 1,
                "is_dir": false,
                "uid": "blt8abe53e5a30335e0",
                "ACL": {},
                "content_type": "image/svg+xml",
                "created_at": "2023-06-30T11:50:57.917Z",
                "created_by": "bltcad6c6f379d5fb4d",
                "file_size": "487",
                "filename": "Check.svg",
                "parent_uid": "blt428f2156d364d6ed",
                "tags": [],
                "title": "Check.svg",
                "updated_at": "2023-06-30T11:50:57.917Z",
                "updated_by": "bltcad6c6f379d5fb4d",
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-07-10T09:13:22.407Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt8abe53e5a30335e0/649ec1a11fa6aa8b6dae02fb/Check.svg"
            },
            "prompt": "Re-write this text to improve writing, enhance search engine optimization, correct spelling and fix grammar {{json_rte_prompt}}: {{field_data}}",
            "submenu": {
                "title": "",
                "value": "",
                "testid": ""
            },
            "show_searchbar": false,
            "testid": "optimize-text-inline-ai"
        },
        {
            "title": "Make shorter",
            "_metadata": {
                "uid": "cs2d69e4732f8275fb"
            },
            "tooltip": "Rephrase the content with a shorter length",
            "icon": {
                "uid": "bltcb88d718632e092f",
                "_version": 1,
                "parent_uid": "blt428f2156d364d6ed",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-09-15T14:44:36.081Z",
                "updated_at": "2023-09-15T14:44:36.081Z",
                "content_type": "image/svg+xml",
                "file_size": "2286",
                "filename": "ArrowsInLineHorizontal.svg",
                "title": "ArrowsInLineHorizontal.svg",
                "ACL": {},
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "time": "2023-09-15T14:52:51.276Z",
                    "user": "bltcad6c6f379d5fb4d",
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/bltcb88d718632e092f/65046dd46f40307d40d10ba6/ArrowsInLineHorizontal.svg"
            },
            "prompt": "Shorten this text by 50% {{json_rte_prompt}} and keep similar capitalization: \n{{field_data}}",
            "submenu": {
                "title": "",
                "value": "",
                "testid": ""
            },
            "show_searchbar": false,
            "testid": "make-shorter-inline-ai"
        },
        {
            "title": "Make longer",
            "_metadata": {
                "uid": "cscfdfa44f0ef868fe"
            },
            "tooltip": "Rephrase the content with a longer length",
            "icon": {
                "uid": "blt543e4f64e441780f",
                "_version": 1,
                "parent_uid": "blt428f2156d364d6ed",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-09-15T14:44:36.086Z",
                "updated_at": "2023-09-15T14:44:36.086Z",
                "content_type": "image/svg+xml",
                "file_size": "1555",
                "filename": "ArrowsOutLineHorizontal.svg",
                "title": "ArrowsOutLineHorizontal.svg",
                "ACL": {},
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "time": "2023-09-15T14:52:51.333Z",
                    "user": "bltcad6c6f379d5fb4d",
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt543e4f64e441780f/65046dd45e99805a590325a7/ArrowsOutLineHorizontal.svg"
            },
            "prompt": "Make this text longer by 50% {{json_rte_prompt}} and keep similar capitalization: \n{{field_data}}",
            "submenu": {
                "title": "",
                "value": "",
                "testid": ""
            },
            "show_searchbar": false,
            "testid": "make-longer-inline-ai"
        },
        {
            "title": "Find synonyms",
            "_metadata": {
                "uid": "cs78039c63241a7015"
            },
            "tooltip": "Find synonyms for important words in the highlighted text",
            "icon": {
                "uid": "blta1c68d778af890bf",
                "_version": 1,
                "parent_uid": "blt428f2156d364d6ed",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-09-11T07:38:27.710Z",
                "updated_at": "2023-09-11T07:38:27.710Z",
                "content_type": "image/svg+xml",
                "file_size": "1006",
                "filename": "Edit_copy.svg",
                "title": "Edit_copy.svg",
                "ACL": {},
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "time": "2023-09-15T14:52:51.299Z",
                    "user": "bltcad6c6f379d5fb4d",
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blta1c68d778af890bf/64fec3f37db54e0beac19a50/Edit_copy.svg"
            },
            "prompt": "Find synonyms for the important words in this text, attempting to re-write it using different words but keeping a similar meaning {{json_rte_prompt}} and keep similar capitalization: \n{{field_data}}",
            "submenu": {
                "title": "",
                "value": "",
                "testid": ""
            },
            "show_searchbar": false,
            "testid": "find-synonyms-inline-ai"
        },
        {
            "title": "Re-write as bulleted list",
            "_metadata": {
                "uid": "cs64feb859a7db09f9"
            },
            "tooltip": "Make this text into a bulleted list",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt7641c90269ac3aa4",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:51:36.328Z",
                "updated_at": "2023-06-30T11:51:36.328Z",
                "content_type": "image/svg+xml",
                "file_size": "1330",
                "filename": "ListBullets.svg",
                "title": "ListBullets.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.257Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt7641c90269ac3aa4/649ec1c81ea82969c4f58663/ListBullets.svg"
            },
            "prompt": "Extract the key insights of this text, then list the ideas in a bulleted list {{json_rte_prompt}} and keep similar capitalization:\n{{field_data}}",
            "submenu": {
                "title": "",
                "value": "",
                "testid": ""
            },
            "show_searchbar": false,
            "testid": "re-write-as-bulleted-list-inline-ai"
        }
    ],
    "model_configuration": {
        "max_tokens": 2048,
        "model": "gpt-3.5-turbo",
        "temperature": 0.75,
        "top_p": null
    },
    "operation_on_current_field": [
        {
            "title": "Optimize text for SEO",
            "_metadata": {
                "uid": "csa7baf16815bf7d27"
            },
            "tooltip": "Enhance SEO, fix grammar and spelling",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blta345833349b2d3fe",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:52:23.829Z",
                "updated_at": "2023-06-30T11:52:23.829Z",
                "content_type": "image/svg+xml",
                "file_size": "1298",
                "filename": "Refresh.svg",
                "title": "Refresh.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.420Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blta345833349b2d3fe/649ec1f7e9365ac642c364bd/Refresh.svg"
            },
            "include_field_data": true,
            "prompt": "Act like a professional editor and re-write this text to enhance search engine optimization, correct spelling and fix grammar {{json_rte_prompt}}: {{field_data}}",
            "submenu": [],
            "show_searchbar": false,
            "testid": "rewrite-this-text"
        },
        {
            "title": "Change tone",
            "_metadata": {
                "uid": "csf540d31595da581f"
            },
            "tooltip": "Change the text's tone of voice.",
            "icon": {
                "uid": "blt11809a94bfa26a7c",
                "_version": 1,
                "parent_uid": "blt428f2156d364d6ed",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-09-11T07:38:54.502Z",
                "updated_at": "2023-09-11T07:38:54.502Z",
                "content_type": "image/svg+xml",
                "file_size": "723",
                "filename": "Chat.svg",
                "title": "Chat.svg",
                "ACL": {},
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "time": "2023-10-23T08:48:26.893Z",
                    "user": "bltcad6c6f379d5fb4d",
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt11809a94bfa26a7c/64fec40e29dd36a3433b5ac4/Chat.svg"
            },
            "include_field_data": true,
            "prompt": "Act as a professional editor, and, while keeping the same underlying meaning, facts, structure and overall length, {{selected_option}}",
            "submenu": [
                {
                    "title": "Persuasive",
                    "_metadata": {
                        "uid": "cs5ae7fc0b99a93116"
                    },
                    "value": "rewrite this content to make it more persuasive and try to convince the reader about the topic.",
                    "test_id": "tone-persuasive"
                },
                {
                    "title": "Straightforward",
                    "_metadata": {
                        "uid": "csa7bb90dcc44ab015"
                    },
                    "value": "rewrite this content to make it straightforward, neutral and focusing on the facts with no emotional undertone.",
                    "test_id": "tone-straightforward"
                },
                {
                    "title": "Friendly",
                    "_metadata": {
                        "uid": "csf2488edc18c454b0"
                    },
                    "value": "rewrite this content to make it sound friendly, conversational and welcoming.",
                    "test_id": "tone-friendly"
                },
                {
                    "title": "Professional",
                    "_metadata": {
                        "uid": "csdfac78ce56dadc1e"
                    },
                    "value": "rewrite this content to make it more authoritative, professional and confident.",
                    "test_id": "tone-professional"
                }
            ],
            "show_searchbar": false,
            "testid": "change-tone"
        },
        {
            "title": "Change length",
            "_metadata": {
                "uid": "cs840e01029a67a1ed"
            },
            "tooltip": "Rewrite content to make it {{selected_option}}.",
            "icon": {
                "uid": "blt752a915d00b2f3b2",
                "_version": 1,
                "parent_uid": "blt428f2156d364d6ed",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-09-11T07:38:42.366Z",
                "updated_at": "2023-09-11T07:38:42.366Z",
                "content_type": "image/svg+xml",
                "file_size": "2428",
                "filename": "ArrowsIn.svg",
                "title": "ArrowsIn.svg",
                "ACL": {},
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "time": "2023-09-15T14:52:51.368Z",
                    "user": "bltcad6c6f379d5fb4d",
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt752a915d00b2f3b2/64fec4025813cb39ad4587e5/ArrowsIn.svg"
            },
            "include_field_data": true,
            "prompt": "Rewrite content to make it {{selected_option}}.",
            "submenu": [
                {
                    "title": "Longer",
                    "_metadata": {
                        "uid": "cs6547c068097d3b3d"
                    },
                    "value": "about 25% longer",
                    "test_id": "rewrite-longer"
                },
                {
                    "title": "Shorter",
                    "_metadata": {
                        "uid": "csb5fef0922bb56da8"
                    },
                    "value": "about 25% shorter",
                    "test_id": "rewrite-shorter"
                }
            ],
            "show_searchbar": false,
            "testid": "shorten-this-text"
        },
        {
            "title": "Translate to current locale language",
            "_metadata": {
                "uid": "cs5e5597a17e69004f"
            },
            "tooltip": "Translate this field into language of this current locale.",
            "icon": {
                "parent_uid": "blt428f2156d364d6ed",
                "uid": "blt6312416370c683c2",
                "created_by": "bltcad6c6f379d5fb4d",
                "updated_by": "bltcad6c6f379d5fb4d",
                "created_at": "2023-06-30T11:51:36.326Z",
                "updated_at": "2023-06-30T11:51:36.326Z",
                "content_type": "image/svg+xml",
                "file_size": "2230",
                "filename": "Language.svg",
                "title": "Language.svg",
                "ACL": {},
                "_version": 1,
                "is_dir": false,
                "tags": [],
                "publish_details": {
                    "environment": "blt6203b467e841f88e",
                    "locale": "en-us",
                    "time": "2023-06-30T12:03:27.473Z",
                    "user": "bltcad6c6f379d5fb4d"
                },
                "url": "https://images.contentstack.io/v3/assets/blt827356091668161a/blt6312416370c683c2/649ec1c829ad9885b3826bcc/Language.svg"
            },
            "include_field_data": true,
            "prompt": "Translate this text into {{current_locale}} {{json_rte_prompt}}: {{field_data}}",
            "submenu": [],
            "show_searchbar": false,
            "testid": "translate-to-current-locale"
        }
    ],
    "organization_uids": [
        {
            "org_uid": "blt222816603cb9a688",
            "_metadata": {
                "uid": "cs2fe93026d7f60322"
            }
        }
    ],
    "section_1": "Replace field content",
    "section_2": "Generate based on another field",
    "tags": [
        "content intelligence",
        "static strings"
    ],
    "title": "App Metadata",
    "updated_at": "2023-12-14T12:08:58.786Z",
    "updated_by": "bltc7eda211e9594b05",
    "publish_details": {
        "time": "2023-12-14T12:09:13.968Z",
        "user": "bltc7eda211e9594b05",
        "environment": "blt6203b467e841f88e",
        "locale": "en-us"
    }
}; exports.default = localAppMetadata;